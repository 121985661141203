.tableContainer {
  background: #fff;
  border-radius: 8px;
  padding: 0 20px;
}

.usersTableHeader {
  padding: 15px 10px;

  .usersTableHeaderTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}
