// Common colours app
$shades-of-red: (
  main: #E00000,
  light: #ed939f,
  dark: #b25500,
);

$shades-of-grey: (
  main: #D1D1D1,
  light: #E8E8E8,
  lighter: #F5F5F5,
  dark: #AFAFAF,
  darker: #7A7A7A,
);

$shades-of-white: (
  main: #fff,
  ice: #F5F6FA
);

// Colours to be used in the timeline and others components
$shades-of-green: (
  vivid: #00D06C,
  main: #4bb543,
  light: #a6d9a3,
  dark: #35942f,
);

$shades-of-blue: (
  main: #8ABCFF,
  light: #DCF2F9,
  dark: #0057CD,
);
$shades-of-purple: (
  main: #B565FF,
  light: #F1E1F7,
  dark: #6500C3,
);

$shades-of-orange: (
  main: #F88B0B,
  medium: #FF9533,
  light: #FECA9C,
  veryLight: #FFF6E2,
  dark: #E46E00,
);

$shades-of-yellow: (
  main: #fed532,
  light: #fff39b,
  dark: #fcbd2b,
);

$black: #000;
$white: #fff;
