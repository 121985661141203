@import './CommonColors.scss';

.tabContainer {
  padding: 20px 30px 0px 30px !important;
  width: 100%;
  height: 100%;
}
// Tab controller
.tabView {
  width: 100%;
  height: 100%;
  max-width: 1200px !important;
  margin: 0 auto 0 auto !important;
  //Tabs bar container
  .ant-tabs-bar {
    margin: 0;
    margin-bottom: 1px;
    border: none !important;
    &.ant-tabs-bottom-bar {
      background: #fff;
      height: 53px;
      width: calc(100% - 60px);
      max-width: 1200px !important;
      box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.26);
      position: fixed !important;
      bottom: 0;
      z-index: 2;
    }
  }
  // Tabs/Button
  .ant-tabs-nav {
    width: 100% !important; //disable tab dynamic size
    // tag
    .ant-tabs-tab {
      //this will need to be revised when adding new tabs
      // dont want to spent to much time one it now
      width: 50%;
      background-color: transparent !important;
      border-color: transparent !important;
      border-radius: 8px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      margin: 0 !important;
      .tabTag {
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
      }
    }
    // remove outline glow on all states
    .ant-tabs-tab, .ant-tabs-tab:focus, .ant-tabs-tab:active ,.ant-tabs-tab-active:focus, .ant-tabs-tab-active:active {
      border: none !important;
      outline: 0 !important;
    }
    // selected tag
    .ant-tabs-tab-active {
      background-color: white !important;
      border: none !important;
      .tabTag {
        font-weight: 500;
      }
    }
  }
    //Bottom tab changes
  .ant-tabs-bottom-bar {
    // tag
    .ant-tabs-tab {
      .tabTag { font-size: 16px; }
    }
  }
  // Content
  .ant-tabs-content {
    height: 100%;
  }
}
.commonTabContent {
  background-color: $white;
  width: 100%;
  min-height: 100% !important;
  padding: 20px 20px 20px 20px;
}
