.settingUpVaultContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  img {
    margin-top: 80px;
    animation: pulse 4s infinite;
  }

  h1 {
    margin-top: 24px;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 0;
  }

  h2 {
    opacity: 0.7;
    margin-top: 0;
  }

  button {
    margin-top: 16px;
  }

  .setting-up {
    margin-top: 24px;
    font-size: 18px;

    span {
      margin-left: 16px;
    }
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}
