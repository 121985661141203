.file-viewer-drawer {
  height: 100% !important;

  .ant-drawer-content-wrapper {
    height: 100% !important;
  }

  .ant-drawer-content {
    background: transparent !important;
  }
}

.fileviewer-container {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}

.fileviewer-header {
  background: linear-gradient(to bottom,rgba(0, 0, 0, 0.7) 0%, transparent 100%);
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  padding: 0 30px;
}

.fileviewer-header span {
  flex: 1;
}

.fileviewer-header .actions button {
  background: transparent;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.2s ease-in;
  padding: 3px 10px;
  margin-left: 10px;
}

.fileviewer-header .actions button:hover {
  background: #F88B0B;
}

.fileviewer-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileviewer-invalid-type {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.fileviewer-invalid-type h1 {
  font-size: 28px;
  color: #fff;
  margin-top: 15px;
}

.viewer-layout-toolbar {
  display: none;
}

.fileviewer-image {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileviewer-image img {
  width: 100%;
  max-width: 1000px;
}

.fileviewer-pdf > div {
  height: 100% !important;
}
