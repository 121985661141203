@import './CommonColors.scss';

//Mixins
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
@mixin disabledInput {
  border-width: 0 !important;
  border: none !important;
  color: $black !important;
  background-color: transparent !important;
  cursor: not-allowed;
  + .anticon {
    opacity: 0;
  }
}
@mixin hoveredInput {
  outline: none;
  border-color: map-get($shades-of-orange, main) !important;
}
// Buttons
.ant-btn:not(.actionButton):not(.noStyle) {
  font-weight: 500 !important;
  padding: 8px 16px !important;
  border-radius: 4px !important;
  font-family: 'Rubik', sans-serif !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 100% !important;
  box-sizing: border-box !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: transparent;

  &:hover,
  &:focus {
    padding: 7px 15px !important;
    border-style: solid !important;
    border-width: 2px !important;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.4 !important;
    border-width: 1px !important;
    padding: 8px 16px !important;
  }

  &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
    margin-left: 0 !important;
  }
}
.ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    // fix
    -webkit-transform: rotate(45deg) scale(1) translate(-100%, -70%) !important;
    -ms-transform: rotate(45deg) scale(1) translate(-100%, -70%) !important;
    transform: rotate(45deg) scale(1) translate(-100%, -70%) !important;
    //
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: '';
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    top: 50% !important;
    left: 50% !important;
    width: 16px !important;
    height: 8px !important;
    margin-bottom: 3px !important;
    background-color: #F88B0B;
    border: 0;
    -webkit-transform: translate(-50%, -50%) scale(.65) !important;
    -ms-transform: translate(-50%, -50%) scale(.65) !important;
    transform: translate(-50%, -50%) scale(.65) !important;
    opacity: 1;
}
.ant-btn-primary {
  &,
  &:disabled,
  &:disabled:hover {
    color: $white !important;
    background-color: map-get($shades-of-orange, main) !important;
    border-color: map-get($shades-of-orange, main) !important;
  }

  &:hover,
  &:focus {
    background-color: map-get($shades-of-orange, main) !important;
    border-color: map-get($shades-of-orange, dark) !important;
  }

  &:active {
    color: rgba($white, 0.7) !important;
    background-color: map-get($shades-of-orange, dark) !important;
    border-color: map-get($shades-of-orange, dark) !important;
  }
}

.ant-btn-secondary,
.ant-btn-ghost {
  &,
  &:disabled,
  &:disabled:hover {
    color: $black !important;
    background-color: $white !important;
    border-color: map-get($shades-of-grey, dark) !important;
  }

  &:hover,
  &:focus {
    background-color: map-get($shades-of-grey, light) !important;
    border-color: map-get($shades-of-grey, dark) !important;
  }

  &:active {
    color: rgba($black, 0.7) !important;
    background-color: map-get($shades-of-grey, dark) !important;
    border-color: map-get($shades-of-grey, dark) !important;
  }

  & > .anticon + span,
  & > span + .anticon {
    margin-left: 10px !important;
  }
}
.ant-btn-icon-only {
  width: 48px !important;
  height: 32px !important;

  & > i {
    width: 100%;
    height: 14px;
    text-align: center;
  }
}
.ant-btn-sm {
  height: 30px !important;
}

.ant-modal-confirm-body {
  .anticon {
    margin-top: 16px;
  }
}

btn, btn-active, button, button:hover, button:focus,
button:active, button:visited {
  box-shadow: none !important;
  outline: none !important;
}
// button:disabled {
//   opacity: 0.8;
//   color: map-get($shades-of-grey, dark) !important;
// }
// button:disabled:hover {
//   opacity: 0.8;
//   color: map-get($shades-of-grey, dark) !important;
// }

// Input
input:hover, input:focus { @include hoveredInput; }
input:disabled { @include disabledInput; }
input {
  box-shadow: none !important;
  outline: 0 !important;
  border-radius: 8px !important;

  @include placeholder {
    color: map-get($shades-of-grey, main) !important;
  }
}

// Ant input
.ant-input {
  border-radius: 4px !important;
  border: 1px solid map-get($shades-of-grey, dark) !important;
}
.ant-form-item {
  margin-bottom: 14px !important;
}
.ant-form-item-label {
  font-weight: 500;
}
.ant-form-item-control {
  // avoid double height when input is wrapper into form.item
  line-height: 30px !important;
}
.ant-input-number {
  width: 100% !important;
}
.ant-input-number-disabled {
  @include disabledInput;
}

// Popover
.ant-popover-title {
  background: map-get($shades-of-grey, light) !important;
  border-bottom: 1px solid map-get($shades-of-grey, main) !important;
  border-radius: 4px 4px 0 0;
}
.ant-popover-inner-content,
.ant-popover-title {
  color: map-get($shades-of-grey, darker) !important;
}

// Stepper
div.ant-steps.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
div.ant-steps-item.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-steps-item {
  min-width: 200px !important;
  margin-right: 20px !important;
}

// Uploader
.ant-upload-picture-card-wrapper {
  width: auto !important;
}

// Icon
.anticon-arrow-left:hover,
.anticon-arrow-left:active,
.anticon-arrow-left:visited,
// Page
.ant-page-header-back-button:active,
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: map-get($shades-of-orange, main) !important;
}

// Virtualized Select
.Select-control {
  border-radius: 4px !important;
  border: 1px solid map-get($shades-of-grey, dark) !important;
}
.Select.is-disabled > .Select-control {
  @include disabledInput;
  .Select-arrow-zone {
    opacity: 0;
  }
}
.Select-control:hover, .Select.is-focused > .Select-control {
  @include hoveredInput;
}
.Select--multi {
  .Select-value { margin-top: 7px !important; }
}
.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none !important;
}

// Modal stuff
.ant-modal-header {
  border-bottom: 0 !important;
  .ant-modal-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
  }
}
.ant-modal-footer {
  border-top: 0 !important;
}
.ant-modal-body {
  padding-top: 0 !important;
}

//Menu
.ant-menu-item .anticon, .ant-menu-submenu-title .anticon  {
  font-size: 18px !important;
  vertical-align: middle;
  line-height: 2.5;
}
.ant-layout-sider-trigger {
  background: linear-gradient(to bottom, map-get($shades-of-orange, main), map-get($shades-of-orange, dark)) !important;
}
.ant-layout-sider-trigger > i > svg {
  color: $white !important;
}
.ant-menu-item:hover {
  color: map-get($shades-of-orange, main) !important;
}
.ant-menu-item.ant-menu-item-selected {
  color: map-get($shades-of-orange, main) !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid map-get($shades-of-orange, main) !important;
}
.ant-layout-sider-light  {
  z-index: 1000 !important;
}
.ant-layout {
  flex-direction: initial !important;
}
//Tooltip
.ant-tooltip-open {
  z-index: 9999;
}
/* Alert */
.ant-alert-warning.ant-alert-banner {
  background-color: #FF5556 !important;
  .ant-alert-warning .ant-alert-icon {
    color: white !important;
  }
  .ant-alert-message {
    color: white !important;
  }
}
