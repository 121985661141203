@import './CommonColors.scss';

.CreateDocketForm {
  &-content {
    background-color: map-get($shades-of-grey, lighther);
    border-radius: 8px;
    margin: 24px 0 0 !important;

    & .ant-form-item-label {
      line-height: 15px !important;
      & label {
        font-size: 12px !important;
        font-weight: normal !important;
      }
    }
  }
}
