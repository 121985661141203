.fileDetails {
	& .ant-drawer-mask{
		background: transparent !important;
	}

  & .drawer-backgound {
    height: calc(50vh - 50px);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 160px 160px;
  }

	& .ant-drawer-content-wrapper {
		width: 80vw !important;
		min-width: 832px !important;
		height: 50vh !important;

		position: absolute !important;
		left: auto !important;
		right: 0 !important;

		@media (max-width: 1280px) {
			& {
				width: 832px !important;
			}
    }
	}
}
