@import './CommonColors.scss';

.DocketController {
  &-content {
    width: 100%;
    background-color: $white;
  }

  &-header {
    & .ant-typography-disabled {
      width: 100%;
      display: block;
      text-align: right;
    }
  }

  &-files {
    & ul {
      padding: 0;
      margin: 0;
      list-style: none;

      & li {
        margin-bottom: 10px;

        > div {
          display: flex;
          align-items: center;
        }

        .file-name {
          display: inline-block;
          white-space: nowrap;
          width: 100%;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        & button {
          margin-left: 15px;
          font-size: 10px !important;
        }
      }
    }
  }

  &-docket {
    padding: 20px 0;
    margin: 15px 0;
  }

  &-autocomplete {
    flex-grow: 1;

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  &-fixed {
    background: red;
    background: #fff;
    margin-bottom: 32px;
    margin-top: 32px;
  }
}

.docketColAttributes {
  margin-top: 16px;
  margin-bottom: 16px;
}
.docketColTags {}
