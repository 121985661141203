.drawer-title {
  small {
    display: block;
    font-size: 14px;
    opacity: .4;
  }

  strong {
    font-size: 24px;
  }
}

.no-vaults {
  background: red;
}
