
@import './CommonColors.scss';
.full-orange-screen-layout-container {
  background: linear-gradient(127.79deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 63.91%, rgba(248, 139, 11, 0) 63.91%), map-get($shades-of-orange, main);
  min-height: 100vh !important;
  overflow-x: none !important;
  padding: 24px 24px 50px;

  * {
    font-family: 'Montserrat', 'Open Sans', sans-serif;
  }

  .color-dark {
    color: map-get($shades-of-orange, dark) !important;
  }

  .content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    max-width: 950px;
    margin: 48px auto 0;
    position: relative;
    z-index: 0;
    
    &.centered {
      height: calc(100vh - 35vh) !important;
    }
    
    .up-content {
      z-index: 999999999;
    }

    .square {
      position: absolute;

      &.large1 {
        top: -10px;
        right: -10px;
      }

      &.large2 {
        bottom: 60px;
        left: 70px;
      }
    }

    .head {
      animation: hardShowUp 1s, fadeIn 1s;

      h1 {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
        letter-spacing: -0.05em;

        @media screen and (max-width: 576px) {
          font-size: 30px;
        }
      }
  
      h3 {
        font-size: 18px;
        text-decoration: underline;
        font-weight: 500;
        color: #e6e6e6;
        margin-bottom: 0;

        @media screen and (max-width: 576px) {
          font-size: 22px;
        }
      }
    }
  }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes showUp {
  from { transform: translateY(40px) }
  to { transform: translateY(0) }
}

@keyframes hardShowUp {
  from { transform: translateY(80px) }
  to { transform: translateY(0) }
}

@keyframes hardShowDown {
  from { transform: translateY(-80px) }
  to { transform: translateY(0) }
}
