@import './CommonColors.scss';

.commonUploadFilesTableContainer {
  .uploadFilesTable {
    .ant-table { border: none; }
    /*Place holder*/
    * { border: none !important; }
  }
  .uploadFilesTableRow {
    &:hover > * {
      background-color: transparent !important;
    }
    .uploadTableColFileName {
      .label {
        font-size: 14px;
        color: $black;
      }
    }
    .uploadTableColFileSize {
      .label {
        font-size: 14px;
        color: $black;
      }
    }
    .uploadTableColStatus {
      .icon {
        font-size: 16px;
      }
      .anticon-check {
        color: map-get($shades-of-green, vivid) !important;
      }
      .anticon-close, .anticon-question {
        color: map-get($shades-of-red, main) !important;
      }
      .anticon-warning {
        color: map-get($shades-of-orange, main) !important;
      }
    }
  }
  .uploadFilesTableHeader {
    font-size: 12px;
    line-height: 100%;
    .ant-table-column-title {
      color: map-get($shades-of-grey, darker) !important;
      font-size: 12px;
    }
  }
}
