@import './CommonColors.scss';

.filterBarRow {
	background-color: map-get($shades-of-grey, lighter);
	padding: 16px;
	border-radius: 8px;

	.filterBarInputColumn {
		flex-grow: 1;
	}

	.filterBarInput {
		input {
			border: 1px solid map-get($shades-of-grey, dark);
			border-radius: 0 !important;
		}
		input:disabled {
			background-color: map-get($shades-of-grey, main);
		}
    .anticon-filter {
      color: map-get($shades-of-grey, dark) ;
    }
	}
}
