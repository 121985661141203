@import './CommonColors.scss';

.dragRejectionModal {
  .ant-modal-header {
    border-bottom: 0 !important;
    padding-top: 30px;
    .ant-modal-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
    }
  }
  .ant-modal-footer {
    border-top: 0 !important;
  }
  .ant-modal-body {
    padding-top: 0 !important;
  }
  // Buttons
  .cancelButton {

  }
  .filesTable {
    margin-top: 8px;
    margin-bottom: 8px;
    & ul {
      padding: 0;
      margin: 0;
      & li {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 20px;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
}
