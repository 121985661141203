@import './CommonColors.scss';

.progressBarContainer {
  padding: 16px;
  margin-top: 16px;
  background: map-get($shades-of-grey, lighter);
  border-radius: 8px;
  .progressBar {
    .ant-progress-inner {
      border-radius: 0 !important;
      background: map-get($shades-of-grey, light);
    }
    .ant-progress-bg {
      border-radius: 0 !important;
      background-color: map-get($shades-of-orange, main);
      height: 40px !important;
      // Stripes
      background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
      background-size: 30px 30px;
      // Animation
      -webkit-animation: progress-bar-stripes 1s linear infinite;
          -moz-animation: progress-bar-stripes 1s linear infinite;
          -ms-animation: progress-bar-stripes 1s linear infinite;
            -o-animation: progress-bar-stripes 1s linear infinite;
              animation: progress-bar-stripes 1s linear infinite;
    }
  }
  .progressBarLabel {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 10px);
    font-size: 14px;
    /* Text Color */
    color: $black;
    text-shadow: 0px 1px 2px $white, 0px 1px 2px $white;
  }
}
// Bar animations
// -------------------------

// Webkit
@-webkit-keyframes progress-bar-stripes {
  from  { background-position: 30px 0; }
  to    { background-position: 0 0; }
}

// Firefox
@-moz-keyframes progress-bar-stripes {
  from  { background-position: 30px 0; }
  to    { background-position: 0 0; }
}

// IE9
@-ms-keyframes progress-bar-stripes {
  from  { background-position: 30px 0; }
  to    { background-position: 0 0; }
}

// Opera
@-o-keyframes progress-bar-stripes {
  from  { background-position: 0 0; }
  to    { background-position: 30px 0; }
}

// Spec
@keyframes progress-bar-stripes {
  from  { background-position: 30px 0; }
  to    { background-position: 0 0; }
}
