.operationCancelModal {
  .ant-modal-header {
    border-bottom: 0 !important;
    .ant-modal-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
    }
  }
  .ant-modal-footer {
    border-top: 0 !important;
  }
  .ant-modal-body {
    padding-top: 0 !important;
  }
  // Buttons
  .cancelButton {

  }
  .retryButton {
    margin-left: 16px;
  }
}
