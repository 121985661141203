@import './CommonColors.scss';

.transferModal {
  .ant-modal-header {
    border-bottom: 0 !important;
    .ant-modal-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
    }
  }
  .ant-modal-footer {
    border-top: 0 !important;
  }
  .ant-modal-body {
    padding-top: 0 !important;
  }
  .cancelButton {

  }
  .confirmationButton {
    margin-left: 16px;
  }
  .selectVault {
    padding-bottom: 5px;
  }
  .DocketController-docket {
    padding: 0;
    margin: 0;
  }
  .DocketController-autocomplete {

  }
  .commonTabContent {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid map-get($shades-of-grey, main);
    box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  }
  .orLabelCol {
    padding: 0 !important;
  }
  .createNewDocketButton {
    width: auto !important;
  }
  .checkedIcon {
    color: map-get($shades-of-green, main);
    font-size: 20px;
    margin-top: 6px;
  }
  .summaryText {
  }
  .anticon-search, .ant-select-arrow-icon {
    font-size: 16px;
    margin-top: -2px;
    color: map-get($shades-of-grey, dark);
  }
  .ant-select-selection {
    border: 1px solid map-get($shades-of-grey, dark);
    border-radius: 0 !important;
	}
  .ant-table-small {
    margin: 0;
  }
  .ant-table-tbody > tr > td {
    border: 0px;
  }
  .ant-table-header {
    background: transparent;
  }
  .ant-table-thead > tr > th {
    padding: 0;
    border: 0px;
    background: white;
    font-weight: normal !important;
    font-size: 12px;
    color: map-get($shades-of-grey, darker);
  }
  .ant-table-tbody > tr > td {
    padding: 5px 5px 0 0;
    // padding: 5px;
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }

  .listDiv {
    position: relative;
    min-height: 250px;

    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: map-get($shades-of-grey, dark);
      height: 100px;
      border-radius: 5px;
    }
    .ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
      border: 0px;
      border-width: 0;
    }
  }
  .sumary {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
.footerSection {
  margin-top: 20px;
}
