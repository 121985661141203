@import './CommonColors.scss';

.operationsControllerContainer {
  background: white !important;
  //position
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  //out-style
  border: 0px solid map-get($shades-of-grey, dark);
  border-radius: 8px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  //inner style
  padding: 0px 0 0 0;
  //container
  max-width: 460px;
  max-height: 350px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  // divider
  & > .ant-col:not(:last-child) {
    border-bottom: 1px solid map-get($shades-of-grey, dark);
  }
  //children
  & > .ant-col {
    padding: 10px 8px 10px 8px;
    flex-direction: row;
    min-width: 400px;
    overflow-y: hidden;
  }
}