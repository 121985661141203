@import './CommonColors.scss';

.tagSelectItem {
  padding: 8px;
  cursor: pointer;
}
.tagsSelect {}
.tagSelectItem:hover, .tagSelectExistingItem:hover {
  color: map-get($shades-of-orange, main);
}
.tagSelectItem:active, .tagSelectExistingItem:active {
  color: map-get($shades-of-orange, dark);
}

.tagSelectExistingItem {
  padding-left: 8px;
  .tagSelectCountBadge {
    margin-left: 10px;
    font-size: 10px !important;
    padding: 0px 5px 0 5px !important;
    line-height: 16px !important;
    height: 18px !important;
    text-decoration: none !important;
  }
}
/* Commented due extra margin it would create on the component, not sure
why this was created on the first place
.Select-menu-outer > .Select-menu > div:not(.Select-noresults) {
  margin-bottom: 16px;
}
*/
