@import './CommonColors.scss';

.transferOperationModal {
  .completeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: -8px;
    .icon {
      margin-right: 8px;
      font-size: 24px;
    }
    .warning {
      color: map-get($shades-of-orange, main);
    }
    .success {
      color: map-get($shades-of-green, main);
    }
  }
  .failedText {
    text-align: center;
    margin: 8px 0;
    .text {
      color: map-get($shades-of-red, main);
    }
  }
  .ant-divider-horizontal {
    margin: 8px 0 24px 0;
  }
  .progressBarContainer {
    padding: 12px;
    margin-top: -16px;
    .ant-progress-bg {
      height: 24px !important;
    }
  }
  .tagsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0 12px 0;
  }
  .sumary {
    width: 100%;
    text-align: center;
  }
  .footerSection {
    margin-top: -8px;
    Button {
      margin-left: 16px;
    }
  }
}

