.wizardContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 40px 24px 0;

  img {
    max-width: 100% !important;
  }

  .carousel {
    height: 100%;
    height: 550px;

    > div {
      display: none;

      &.visible {
        display: block;
      }

      h1 {
        font-size: 36px;
        font-weight: bold;
        margin: 0;

        @media screen and (max-width: 600px) {
          font-size: 32px;
        }
      }

      h2 {
        margin: 40px 0 24px;
        font-size: 24px;
        opacity: 0.8;

        @media screen and (max-width: 600px) {
          font-size: 18px;
          margin: 16px 0 24px;
        }
      }

      .image-container {
        background: #fff;
        padding: 24px;
        width: 100%;
        max-width: 548px;
        border-radius: 5px;
        margin:0 auto;
        box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .carousel-controllers {
    margin-top: 24px;

    button {
      background: #ccc;
      border: none;
      width: 25px;
      height: 13px;
      border-radius: 5px;
      margin: 0 8px;
      cursor: pointer;
      transition: all 0.2s ease-in;

      &:hover {
        background: #F88B0B;
        opacity: 0.5;
      }

      &.active {
        transition: all 0.2s ease-in;
        background: #F88B0B;
        width: 40px;
      }
    }
  }
}
