.documentDetails {
	& .ant-drawer-mask {
		background: transparent !important;
	}

	& .ant-drawer-content-wrapper {
		width: 100vw !important;
		height: 65vh !important;

		position: absolute !important;
		left: auto !important;
		right: 0 !important;
	}
}