@import './CommonColors.scss';

.attrSelectItem {
  padding: 8px !important;
}
.attrSelect {}
.attrSelectItem:hover, .attrSelectExistingItem:hover {
  color: map-get($shades-of-orange, main);
}
.attrSelectItem:active, .attrSelectExistingItem:active {
  color: map-get($shades-of-orange, dark);
}

.attrSelectExistingItem {
  padding-left: 8px;
  .attrSelectCountBadge {
    margin-left: 10px;
    font-size: 10px !important;
    padding: 0px 5px 0 5px !important;
    line-height: 16px !important;
    height: 18px !important;
    text-decoration: none !important;
  }
}
