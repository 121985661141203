@import './CommonColors.scss';

body {
  padding: 0;
  // min-width: 700px !important;
}

section {
  background-color: map-get($shades-of-grey, lighter) !important;
}

/* Authorized container */
section.container-layout {
  height: calc(100vh - 115px) !important;
  overflow-y: auto;
}
section.container-layout-headerless {
  height: calc(100%) !important;
}

// IDM
.idmBadgeContainer {
  background-color: black !important;
  border-color: black !important;
  background-color: transparent !important;
  border-color: transparent !important;
  .idmBadgeUserIcon {
    width: 45px !important;
    height: 45px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px !important;
    color: white !important;
    color: black !important;
    background-color: #D47606 !important;
    border: 2px solid $white;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    box-sizing: content-box;
    &:hover {
      background-color: #BD7014 !important;
    }
    .idmBadgeUserText {
      line-height: 2.4;
      color: map-get($shades-of-white, main) !important;
    }
  }

  & .idmBadgeUserName{
    margin-left: 10px;
    font-size: 13px;
  }


  &.collapsed {
    width: 100%;
    margin-right: 0;

    .idmBadgeUserIcon{
      margin: 0 auto;
      display: block;
    }
  }
}


/* Page - w/ nav and header */
.pageHeader {
  margin-top: 10px !important;
  border-bottom-width: 1px;
}
/* Page - fullscreen */
.pageContentFullScreen {
  padding: 30px;
  height: 100%;
  background-color: map-get($shades-of-grey, lighter) !important;
  &.noPadding {
    padding: 0;
  }
}
.pageContent {
  width: 100%;
  // height: 100%; -- removed in order to make margin below to work with flex
  max-width: 1200px !important;
  margin: 0 auto 20px auto !important;
  // Bugfix, when below 1200px docket view (for example) gets no 'padding'
  @media (max-width: 1220px) {
    & { padding: 0 20px 0 20px; }
  }
}




/* Default logo */
.logo {
  height: 80px;
  margin-bottom: 20px;
}

// Deletion warning alert
.desktopDeletionWarningAlertButton {

  margin-left: -15px !important;
  margin-right: -15px !important;
  color: white !important;
  span {
    text-decoration: underline;
  }
}
.desktopDeletionWarningAlertButton:hover, .desktopDeletionWarningAlertButton:active {
  color: rgba(255,255,255,.7) !important;
}
