@import './CommonColors.scss';

.targetBox,
.targetBoxHover {
  margin: 25px 20px 0 20px !important;
  padding: 10px;
  border: 1px dashed;
  width: calc(100% - 44px);
  height: 100%;
  min-height: 250px;
  z-index: 0;
  text-align: center;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .ant-typography {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .buttonRow {
    margin-top: 24px;
  }
  .filesTable {
    margin-top: 8px;
    margin-bottom: 8px;
    & ul {
      padding: 0;
      margin: 0;
      & li {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 20px;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
}

.targetBox {
  border-color: map-get($shades-of-grey, dark);
  color: map-get($shades-of-grey, dark);
  fill: map-get($shades-of-grey, dark);
}

.targetBoxHover {
  border-color: map-get($shades-of-orange, main);
  background-color: map-get($shades-of-grey, light);
  color: map-get($shades-of-orange, main);
  fill: map-get($shades-of-orange, main);

  & .ant-typography {
    color: map-get($shades-of-orange, main);
    fill: map-get($shades-of-orange, main);
  }
}

.targetSuccess {
  border-color: map-get($shades-of-green, vivid);
  color: map-get($shades-of-green, vivid);
  fill: map-get($shades-of-green, vivid);

  & .ant-typography {
    color: map-get($shades-of-green, vivid);
    fill: map-get($shades-of-green, vivid);
  }
}

// .targetError {
//   border-color: map-get($shades-of-red, main);
//   color: map-get($shades-of-red, main);
//   fill: map-get($shades-of-red, main);

//   & .ant-typography {
//     color: map-get($shades-of-red, main);
//     fill: map-get($shades-of-red, main);
//   }
// }

.targetDisabled {
  border-color: map-get($shades-of-grey, dark);
  color: map-get($shades-of-grey, dark);
  fill: map-get($shades-of-grey, dark);
  opacity: .7;
}
