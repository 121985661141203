@import './CommonColors.scss';

.transferOperationContainer {
  //Main content container
  .transferContainerRow { //By columns
    //First columns
    .transfer-icon {
      font-size: 30px;
    }
    .transferSizeLabel {
      font-size: 11px !important;
      text-align: center !important;
      display: flex;
    }

    // Second column
    .transferTitleMain {
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $black;
      margin-right: 4px;
    }
    .transferTitleSub {
      // limit width with ellipsis
      // width: 210px;
      // height: 15px;
      // overflow: hidden;
      // display: inline-block;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // margin-bottom: -2.5px;
      //
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: map-get($shades-of-orange, main);


    }
    .transferProgressLabel {
      font-size: 12px !important;
    }
    .transferAutoRetryTag {
      margin-top: 4px;
      font-size: 11px !important;
      font-weight: 500 !important;
      padding: 0 2px !important;
    }
    //Badge container
    .transferProgressBadges {
      opacity: .5;
      .ant-badge-not-a-wrapper {
        .ant-badge-status-dot {
          margin-top: 2px;
        }
        .ant-badge-status-text {
          font-size: 10px;
          margin-left: 2px !important;
        }
      }
    }

    // Upload circle
    .transferProgressCircle {
      margin-top: 0px;
    }
    .transferProgressCircle.ant-progress-status-active {
      .ant-progress-circle {
        // Animation
        -webkit-animation: progress-circle-rotation 1s linear infinite;
           -moz-animation: progress-circle-rotation 1s linear infinite;
            -ms-animation: progress-circle-rotation 1s linear infinite;
             -o-animation: progress-circle-rotation 1s linear infinite;
                animation: progress-circle-rotation 1s linear infinite;
      }
    }
    .transferProgressCircle.ant-progress-status-success {
      .ant-progress-text {
        .anticon-check {
          color: map-get($shades-of-orange, main);
          font-size: 19px;
        }
      }
    }
    .transferProgressCircle.ant-progress-status-exception {
      .ant-progress-text {
        .anticon-close {
          color: map-get($shades-of-red, main);
          font-size: 19px;
        }
      }
    }
  }
  .transferProgressButtonsRow {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: visibility 0s linear 500ms, opacity 150ms linear 200ms, height 300ms linear 380ms;
    .ant-divider-horizontal {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .ant-btn-sm {
      height: 20px !important;
      line-height: 35% !important;
      span {
        font-size: 12px;
      }
    }
  }
}
// Hover
.transferOperationContainer:hover {
  .transferProgressButtonsRow {
    visibility: visible;
    opacity: 1;
    height: 40px;
    transition: visibility 0s linear 280ms, opacity 300ms linear 280ms, height 300ms linear 200ms;
  }
}
// Progress animations
// -------------------------
// Webkit
@-webkit-keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

// Firefox
@-moz-keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

// IE9
@-ms-keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

// Opera
@-o-keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

// Spec
@keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}
