@import './CommonColors.scss';

.docketDetails {
	transform: none !important;
	& .ant-drawer-content-wrapper {
		width: 80vw !important;
		min-width: 832px !important;

		@media (max-width: 1280px) {
			& {
				width: 832px !important;
			}
		}
  }

	&-toggleRadioButton {
		width: 100%;
		margin: 16px 0 !important;

		& > * {
			width: 50%;
			text-align: center;
		}
	}

	&-informationTab {
		& span.ant-typography {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 100%;
			/* identical to box height, or 12px */


			/* Text Color */

			color: $black;

			/* Inside Auto Layout */

			flex: none;
			order: 0;
			align-self: flex-start;
			margin: 0px 10px;

			& strong {
				font-size: 16px;
				line-height: 100%;
				/* identical to box height, or 16px */


				/* Text Color */

				color: $black;

				/* Inside Auto Layout */

				flex: none;
				order: 1;
				align-self: flex-start;
				margin: 0px 10px
			}
		}
  }

  .docketDetailsItem {
    span, strong {
      margin-left: 0 !important;
    }
  }

  .ant-skeleton {
    li {
      margin-left: 0 !important;
    }
  }
}

.filesTable {
	&.filesTableSelectedRows {
		height: calc(50vh - 176px) !important;
		overflow: auto;
	}
	.ant-table, table, table > :not(.filesTableHeader) {
		border-collapse: collapse !important;
		max-height: 100%;
	}
	.ant-table {
		border: none;
		background-color: white !important;
    .ant-checkbox-inner {
      -ms-transform: scale(1.2); /* IE */
      -moz-transform: scale(1.2); /* FF */
      -webkit-transform: scale(1.2); /* Safari and Chrome */
      -o-transform: scale(1.2); /* Opera */
      transform: scale(1.2);
    }
		/*Place holder*/
		*:not(.ant-checkbox-inner), .ant-table-selection-column {
			border: none !important;
		}

    .deletedItemColumn {
      opacity: 0.3;
    }

		/*Table header*/
		.filesTableHeader {
			border: none !important;
			th {
				padding: 0 0 4px 0 !important;
				background-color: $white;
			}
			th .ant-table-header-column {
				vertical-align: bottom !important;
			}
			/*Title*/
			.ant-table-column-title {
				color: map-get($shades-of-grey, darker) !important;
				font-size: 12px;
			}
			.ant-table-column-sorters:hover > .ant-table-column-title{
				color: map-get($shades-of-orange, main) !important;
			}
			th .ant-table-header-column .ant-table-column-sorters:hover::before {
				background: transparent !important;
			}
			/*Hide sorter*/
			// .ant-table-column-sorter {
			// 	display: none;
			// }
		}
		/*Row*/
		.ant-table-tbody > tr > td  {
			border: 1px solid map-get($shades-of-grey, light) !important;
			border-left: none !important;
			border-right: none !important;
			padding: 6px 0 !important;
		}
		.ant-table-tbody > tr > td:last-child  {
			border-right: 1px solid map-get($shades-of-grey, light) !important;
		}
		.ant-table-tbody > tr > td:nth-child(2)  {
			border-left: none !important;
		}
		.ant-table-tbody > tr > td:nth-child(1)  {
			border-left: 1px solid map-get($shades-of-grey, light) !important;
		}

		.filesTableRow  {
			// vertical-align: top;
			margin-bottom: 1px !important;
			/*Row columns*/
			.filesTableColIcon, .filesTableColName {
				.label {
					color: $black;
					font-weight: 500;
				}
				margin-left: 2px !important;
				margin-right: 4px !important;
			}
			// .filesTableColName {
			// 	// white-space: nowrap !important;
			// 	// max-width: calc(max(24vw)) !important;
			// 	// overflow: hidden;
			// 	// text-overflow: ellipsis;
			// }
			.filesTableColCreationDate {
				.label {
					color: map-get($shades-of-grey, darker) !important;
				}
        margin-right: 14px !important;
        white-space: nowrap;
			}
			.filesTableColType {
				.label {
					color: map-get($shades-of-grey, darker) !important;
				}
        margin-right: 14px !important;
        white-space: nowrap;
      }
      .filesTableColDeleted {
        width: 120px;
      }
			.filesTableDeleteButton {
				// background: rgba(255, 255, 255, 0.9) !important;
				/* Drop Area Strokes */

				border: 1px solid map-get($shades-of-grey, dark) !important;
				box-sizing: border-box  !important;
				border-radius: 4px !important;

				&.ant-btn-icon-only {
					font-size: 14px;
				}

				& > i {
					width: 100% !important;
					text-align: center !important;
				}
			}
			.filesTableDownloadButton {
				padding: 3px 12px !important;

				// margin: 13px 16px 0 0 ;

				min-width: 48px !important;
				height: 30px !important;

				// background: rgba(255, 255, 255, 0.9) !important;
				/* Drop Area Strokes */

				border: 1px solid map-get($shades-of-grey, dark) !important;
				box-sizing: border-box  !important;
				border-radius: 4px !important;

				&.ant-btn-icon-only {
					font-size: 14px;
				}

				& > i {
					width: 100% !important;
					text-align: center !important;
				}
			}


			&.ant-table-row-selected  {
				td {
					background-color: map-get($shades-of-orange, veryLight) !important;
				}
			}
		}
	}
}

.filesTable:not(.multiselection) {
	.ant-table {
		th, td {
			&:first-child {
				display: none !important;
			}
    }
		.ant-table-tbody > tr > td:nth-child(2)  {
			border-left: 1px solid map-get($shades-of-grey, light) !important;
		}
		.ant-table-tbody > tr > td:nth-child(1)  {
			border-left: none !important;
		}
	}
	.filesTableRow  {
		&.ant-table-row-selected  {
			td {
				background-color: map-get($shades-of-orange, main) !important;
			}
			.filesTableColDocket {
				.label {
					color: $white !important;
				}
			}
			.filesTableColName {
				.label {
					color: $white !important;
				}
			}
			.filesTableColCreationDate {
				.label {
					color: $white !important;
				}
			}
			.filesTableColType {
				.label {
					color: $white !important;
				}
			}
		}
	}
}
