@import './CommonColors.scss';

.attributeForm {
  & label {
    color: map-get($shades-of-purple, dark) !important;
  }
  .ant-form-item-label {
    line-height: 30px !important;
  }
}
