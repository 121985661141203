@import './CommonColors.scss';

.docketHeader {
	transform: none !important;

	&-toggleRadioButton {
		width: 100%;
		margin: 16px 0 !important;

		& > * {
			width: 50%;
			text-align: center;
		}
	}

	.infoRow {
		& span.ant-typography {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 100%;

			/* Text Color */

			color: $black;

			/* Inside Auto Layout */

			flex: none;
			order: 0;
			align-self: flex-start;
			margin: 0px 10px;

			& strong {
				font-size: 16px;
				line-height: 100%;

				/* Text Color */
				color: $black;

				/* Inside Auto Layout */
				flex: none;
				order: 1;
				align-self: flex-start;
				margin: 0px 10px
			}
		}
  }

  .infoCol {
		padding: 2px 8px 2px 8px !important;
    span, strong {
      margin-left: 0 !important;
    }
		.title {
			text-decoration: underline !important;
		}
  }
	.infoCol.attributes {
		padding: 8px 8px 8px 8px !important;
		.attributeForm {
			margin-bottom: 5px !important;
		}
	}

  .ant-skeleton {
    li {
      margin-left: 0 !important;
    }
  }
}

