@import './CommonColors.scss';

.invitationLogo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  border-color: map-get($shades-of-grey, lighter);
}
