@import './CommonColors.scss';

.downloadOperationView {
  //Main content container
  .downloadContainerRow { //By columns
    //First columns
    .download-icon {
      font-size: 30px;
    }
    .downloadSizeLabel {
      font-size: 11px !important;
      text-align: center !important;
    }

    // Second column
    .downloadTitleMain {
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $black;
      margin-right: 4px;
    }
    .downloadTitleSub {
      // limit width with ellipsis
      // width: 210px;
      // height: 15px;
      // overflow: hidden;
      // display: inline-block;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // margin-bottom: -2.5px;
      // 
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: map-get($shades-of-orange, main);
    }
    .downloadProgressLabel {
      font-size: 12px !important;
    }

    // Download circle
    .downloadProgressCircle {
      margin-top: 0px;
    }
    .downloadProgressCircle.ant-progress-status-active {
      .ant-progress-circle {
        // Animation
        -webkit-animation: progress-circle-rotation 1s linear infinite;
           -moz-animation: progress-circle-rotation 1s linear infinite;
            -ms-animation: progress-circle-rotation 1s linear infinite;
             -o-animation: progress-circle-rotation 1s linear infinite;
                animation: progress-circle-rotation 1s linear infinite;
      }
    }
    .downloadProgressCircle.ant-progress-status-success {
      .ant-progress-text {
        .anticon-check {
          color: map-get($shades-of-orange, main);
          font-size: 19px;
        }
      }
    }
    .downloadProgressCircle.ant-progress-status-exception {
      .ant-progress-text {
        .anticon-close {
          color: map-get($shades-of-red, main);
          font-size: 19px;
        }
      }
    }
  }
  .downloadProgressButtonsRow {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: visibility 0s linear 500ms, opacity 150ms linear 200ms, height 300ms linear 380ms;
    .ant-divider-horizontal {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .ant-btn-sm {
      height: 20px !important;
      line-height: 35% !important;
      span {
        font-size: 12px;
      }
    }
  }
}
// Hover
.downloadOperationView:hover {
  .downloadProgressButtonsRow {
    visibility: visible;
    opacity: 1;
    height: 40px;
    transition: visibility 0s linear 280ms, opacity 300ms linear 280ms, height 300ms linear 200ms;
  }
}
// Progress animations
// -------------------------
// Webkit
@-webkit-keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

// Firefox
@-moz-keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

// IE9
@-ms-keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

// Opera
@-o-keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

// Spec
@keyframes progress-circle-rotation {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}
