@import './CommonColors.scss';

.commonTagsLabels {
  display: inline;
  .tag {
    margin-bottom: 2px;
    background-color: map-get($shades-of-blue, light) !important;
    color: map-get($shades-of-blue, dark) !important;
    border: none;
    border-radius: 0;
    font-weight: 500;
    font-size: 11px;
  }
}
