@import './CommonColors.scss';

.uploadFileErrorModal {
  .ant-modal-content {
    width: 600px;
  }
  // Subtitle
  .subtitleLabelRow {
    .subtitleLabel {
      font-size: 16px;
      color: $black;
    }
  }
  // Footer
  .retryLabel {
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 16px;
    color: $black;
  }
  // Buttons
  .cancelButton {

  }
  .retryButton {
    margin-left: 16px;
  }

  // Files table
  .CommonUploadFileErrorModal-files {
    margin-top: 8px;
    margin-bottom: 8px;
    & ul {
      padding: 0;
      list-style: none;
      margin: 0;
      & li {
        margin-top: 8px;
        margin-bottom: 8px;
        font-weight: 300;
        font-size: 14px;
        display: table;
        & button {
          opacity: 0;
          margin-left: 15px;
          font-size: 10px !important;
        }

        &:hover {
          & button {
            opacity: 1;
          }
        }
      }
    }
  }
}
