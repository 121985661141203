$primary: #F88B0B;
$content-height: 55px;

.new-appbar {
  height: 90px;
  background: $primary;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.33);
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  &__content {
    width: 100%;
    max-width: 1248px;
    padding: 0 24px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    &__left,
    &__center,
    &__right {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &__center {
      justify-content: center;

      .current-vault {
        display: none;
        color: #fff;

        small {
          font-size: 13px;
          display: block;
          opacity: .6;
        }

        strong {
          font-size: 18px;
        }

        @media screen and (max-width: 400px) {
          display: block;
        }
      }

      .logo {
        width: 156px;
        margin-top: 18px;
      }

      @media screen and (max-width: 674px) {
        .logo {
          display: none;
        }
      }

      @media screen and (max-width: 674px) {
        justify-content: start;
        margin-left: 16px;

        .logo {
          display: none;
        }
      }
    }

    &__left {
      @media screen and (max-width: 674px) {
        flex: 0;
      }

      .mobile-hamburger {
        display: none;

        @media screen and (max-width: 400px) {
          display: block;
        }

        button {
          background-color: transparent;
          border: none;
          font-size: 22px;
          color: #fff;
          cursor: pointer;
        }
      }

      .dropdown-button {
        @media screen and (max-width: 400px) {
          display: none;
        }

        background: #fff;
        min-width: 250px;
        height: $content-height;
        padding: 0 16px;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        float: left;

        > div {
          display: flex;

          img {
            margin-right: 16px;

            &.piggy {
              margin-top: -4px;
            }
          }

          .vault-name {
            display: flex;
            flex-direction: column;
            line-height: 20px;

            small {
              color: #929292;
            }

            strong {
              display: block;
              max-width: 210px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        strong {
          font-size: 16px;
          color: $primary;
        }

        .arrow {
          margin-left: 16px;
          color: $primary;
        }
      }
    }

    &__right {
      justify-content: flex-end;

      @media screen and (max-width: 400px) {
        display: none;
      }
    }
  }
}







.appbar-container {


  .vault-container {

  }

  .logo-container {
    height: $content-height;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-container {
    height: $content-height;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.alert-banner-wrapper {
  margin-top: -24px;
}

.alert-banner {
  background: transparent;
  ul, li {
    margin: 0;
    padding: 0 16px;
  }

  > ul {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    list-style-type: none;
    > li {
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: #fff;
      }
    }
  }

  .alert-banner-item-details {
    margin-left: 20px;
    text-decoration: underline;
    &:hover {
      opacity: .8;
    }
  }
}
// Is externally defined from alert-banner because its also used on popover
.callout-icon {
  margin-right: 8px;
}

.vault-dd-navigation {
  background: $primary !important;
  height: 75px;
  margin-top: -4px !important;
  display: flex;
  justify-content: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  > div {
    margin-top: 8px;
  }

  a {
    color: #A95E05;
    border: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 80px;
    height: 50px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.2s ease-in;

    &.active {
      color: #fff;
      font-weight: bold;
      background: rgba(0, 0, 0, 0.1);
    }

    &:hover {
      color: #fff;
      background: rgba(0, 0, 0, 0.05);
    }

    i {
      font-size: 22px;
    }
  }
}

.vault-dd-switch-vauls-title {
  height: 35px;
  background: #fff !important;

  span {
    display: block;
    margin-top: 5px;
    color: #929292;
    font-size: 12px;
  }
}

.vault-dd-switch-vauls-divider {
  > div {
    height: 1px;
    background: #EFEFEF !important;
    margin-top: 16px;
  }
}

.vault-dd-vault-option {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: #fff !important;
  min-width: 250px !important;

  &:not(:last-child) {
    border-bottom: 1px solid #EFEFEF;
  }

  &:hover,
  &.active {
    background: rgba(248, 139, 11, 0.06) !important;
  }

  span {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width: 100%;
    height: 100%;
    justify-content: center;
    border-radius: 4px;
    padding-right: 12px;

    small {
      font-size: 10px;
      color: $primary;
      text-transform: uppercase;
    }
  }

  small {
    color: #8D8D8D;
  }

  .options {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 3px 0 15px;

    a {
      color: #CCCCCC;
      border: 0;
      background: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      width: 65px;
      height: 48px;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.2s ease-in;

      &.active {
        color: #fff;
        background: $primary;
      }

      &:hover:not(.active) {
        color: $primary;
      }

      i {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .trial-tag {
    display: none !important;
  }
}
