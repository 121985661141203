@use "sass:math";
@import './CommonColors.scss';

.commonSearchResultsContainer {
	max-height: 100% !important;
	margin: 0px 0px 0px 15px !important;
	.resultsHeader {
		margin: 16px 0px 8px 0px !important;
		color: map-get($shades-of-grey, darker);
		font-size: 14px;
	}
	.ant-table-wrapper, .resultsTable, .ant-table-body {
		overflow-y: auto !important;
	}
	.resultsHeaderDivider {
		margin: 10px 0px 10px 8px !important;
		min-width: calc(100% - 16px) !important;
		width: calc(100% - 16px) !important;
	}
}

.resultsTable {
	.ant-table, table, table > :not(.resultsTableHeader) {
		border-collapse: collapse !important;
		max-height: 100%;
	}
	.ant-table {
		border: none;
		/*Place holder*/
		> * {
			border: none !important;
		}

		/*Table header*/
		.resultsTableHeader {
			border: none !important;
			th {
				padding: 0 0 4px 0 !important;
				background-color: $white;
			}
			th .ant-table-header-column {
				vertical-align: bottom !important;
			}
			/*Title*/
			.ant-table-column-title {
				color: map-get($shades-of-grey, darker) !important;
				font-size: 12px;
			}
			.ant-table-column-sorters:hover > .ant-table-column-title {
				color: map-get($shades-of-orange, main) !important;
			}
			th .ant-table-header-column .ant-table-column-sorters:hover::before {
				background: transparent !important;
			}
			/*Hide sorter*/
			// .ant-table-column-sorter {
			// 	// display: none;
			// }
      .small-screen-document {
        width: 25%;
        min-width: 120px;
      }
      .small-screen-docket {
        width: 15%;
        min-width: 120px;
      }
		}
		/*Row*/
		.ant-table-tbody > tr > td  {
			border: 1px solid map-get($shades-of-grey, light) !important;
			padding: 6px 0 !important;
		}
		.ant-table-tbody > tr > td:not(:last-child)  {
			border-right: none !important;
		}
		.ant-table-tbody > tr > td:not(:first-child)  {
			border-left: none !important;
		}
		.resultsTableRow  {
      .deletedItemColumn {
        opacity: 0.3;
      }
			margin-bottom: 1px !important;
			/*Row columns*/
			.resultsTableColDocket, .resultsTableColName, .resultsTableColIcon {
				.label {
					color: $black;
					font-weight: 500;
				}
				margin-left: 2px !important;
				margin-right: 2px !important;
			}
			.resultsTableColName {
				white-space: nowrap !important;
				max-width: calc(max(24vw, 280px)) !important;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.resultsTableColCreationDate {
				.label {
					color: map-get($shades-of-grey, darker) !important;
				}
			}
			.resultsTableColType {
				.label {
					color: map-get($shades-of-grey, darker) !important;
				}
			}
			.resultsTableColAction {
				.resultsTableDownloadButton {
					margin: 0px 10px 0px 0;
				}
				.ant-btn {
					font-weight: 700 !important;
					padding: 0px 0px !important;
					border-radius: 4px !important;
					font-size: 14px !important;
					line-height: 100% !important;
					box-sizing: border-box !important;
					border-style: solid !important;
					border-width: 1px !important;
					border-color: transparent;
					width: 34px !important;
					height: 30px !important;
					&:hover,
					&:focus {
						padding: 0px 0px !important;
						border-style: solid !important;
						border-width: 2px !important;
					}

					&:disabled,
					&:disabled:hover {
						opacity: 0.4 !important;
						border-width: 1px !important;
						padding: 0px 0px !important;
					}
				}
			}
		}
	}

}
