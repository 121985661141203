@import './CommonColors.scss';
@import './ikon_ant.scss';

#hidden {
  display: none !important;
  * > {
    display: none !important;
  }
}

html, body, #root {
  min-height: 100vh !important;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif !important;
  // -webkit-font-smoothing: antialiased !important;
  // -moz-osx-font-smoothing: grayscale !important;
  // min-width: 800px;
}
// Fixes relative to font stuff
button {
  line-height: 1.5 !important;
}



* {
  font-family: 'Montserrat', 'Rubik', sans-serif;
}


.paid-feature-flag {
  font-size: 12px;
  color: #637BF3;
}


// TODO: Move to ant file!
.ant-checkbox-inner::after {
  left: 50% !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-100%, -20%) !important;
}

.ant-typography.ant-typography-disabled {
  color: map-get($shades-of-grey, darker) !important;
}
