@import './CommonColors.scss';

.dragAndDropSection {
  min-height: 100vh !important;
}
.dragAndDropContainer {
  min-height: 100vh !important;

  @media screen and (max-width: 600px) {
    background: #fff !important;
  }
}
