@import './CommonColors.scss';

.inUploadContent {
  position: relative;
  .uploadResultContainer {
      margin-top: 40px;
      padding: 16px 0;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      border-bottom: solid 1px map-get($shades-of-grey, light);
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 9;
      box-shadow: 0 14px 24px -14px rgba(0, 0, 0, .1);
      .uploadResultSuccessUpper, .uploadResultFailedUpper {
        margin-bottom: 24px;
        .anticon {
          color: map-get($shades-of-green, vivid) !important;
          font-size: 56px;
        }
        .ant-typography {
          font-weight: 500;
          font-size: 30px;
          line-height: 100%;
          color: $black;
          margin-left: 16px;
        }
      }
      .uploadResultFailedUpper {
        .anticon {
          color: map-get($shades-of-orange, main) !important;
        }
        .subtitle {
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          color: map-get($shades-of-red, main);
          opacity: .75;
          margin-left: 16px;
        }
      }
  }
  //Title
  .uploadTitleContainer {
    .uploadTitleMain {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: $black;
      margin-right: 4px;
    }
    .uploadTitleSub {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: map-get($shades-of-orange, main);
    }
  }
  //Subtitle
  .uploadSubtitleContainer {
    margin-top: 16px;
    .uploadSubtitleMain {
      color: $black;
      font-size: 16px;
      line-height: 100%;
    }
  }
  //Table
  .uploadProgressTableContainer {
    margin-top: 16px;
  }
  //Buttons
  .uploadProgressButtonsContainer {
    margin-top: 16px;
  }
}
