@import './CommonColors.scss';

.commonAttributesLabels {
  display: inline;
  .tag {
    margin-bottom: 2px;
    background-color: map-get($shades-of-purple, light) !important;
    border: none;
    font-weight: 500;
    font-size: 11px;
    border-radius: 0;
    .tagPrefix {
      color: map-get($shades-of-purple, dark) !important;
    }
    .tagSuffix {
      color: $black;
      margin-left: 8px;
    }
  }
}
