@import './CommonColors.scss';

$defaultSpacing: 20px;
$defaultPadding: 8px;

.commonSearchFilter {
    padding-bottom: 10px;
    .mainOptionsRow {
      background-color: $white;
      padding: ($defaultPadding * 2) $defaultPadding $defaultPadding $defaultPadding ;
      border-radius: 0 !important;
      .optionsCol {
        display: inline;
      }
      .prefixLabelCol {
        font-weight: 500;
        margin-right: $defaultSpacing;
        color: $black;
      }
      .checkboxCol {
        margin-left: $defaultSpacing * 4;
      }
      .checkboxLabelCol {
        margin-left: $defaultSpacing/2;
        margin-right: $defaultSpacing;
      }
      .tooltipCol {}
    }
    .filtersItemDivider {
      margin: 0 0 ($defaultPadding * 2) 0 !important;
      border-color: $black !important;
      border-radius: 0 !important;
    }
    .filtersCreateRow {
      background-color: $white;
      padding: $defaultPadding;
      padding-bottom: $defaultPadding * 2;
      border-radius: 0 !important;
      .prefixLabelCol {
        margin-right: $defaultSpacing;
      }
      .fieldSelectCol {
        margin-right: $defaultSpacing;
      }
      .qualifierSelectCol {
        margin-right: $defaultSpacing;
      }
      .valueInputCol {
        margin-right: $defaultSpacing;
      }
      .conditionalLabelCol {
        margin-right: $defaultSpacing;
      }
      .valueInput2Col {
        margin-right: $defaultSpacing;
      }
      .addButtonCol {}
    }
    .filtersItemRow {
      &.first {}
      &.last {
        padding-bottom: $defaultPadding * 2 !important;
      }
      background-color: $white;
      padding: $defaultPadding;
      border-radius: 0 !important;
      .removeButtonCol {
        margin-right: 5vh;
        margin-left: auto;
        .ant-btn {
          background-color: map-get($shades-of-grey, light) !important;
        }
      }
      .fieldLabelCol, .valueLabelCol, .value2LabelCol {
        margin-right: $defaultSpacing;
        border-style: solid;
        border-color: map-get($shades-of-grey, light) !important;
        background-color: map-get($shades-of-grey, lighter) !important;
        border-width: 2px;
        padding: 2px 8px;
        max-width: 60%;
        word-break: break-all;
        .ant-typography {
          color: map-get($shades-of-orange, main) !important;
          font-weight: 600;
        }
      }
      .qualifierLabelCol, .conditionalLabelCol {
        margin-right: $defaultSpacing;
        border-style: solid;
        border-color: map-get($shades-of-orange, light) !important;
        border-width: 2px;
        border-radius: 20px;
        background-color: lighten(map-get($shades-of-orange, light), 10%) !important;
        padding: 1px 8px;
        .ant-typography {
          color: map-get($shades-of-grey, darker) !important;
          font-weight: 600;
        }
      }
    }
}
.searchFilterTooltipContent {
  max-width: 300px;
}
