@import './CommonColors.scss';

.EditAttributesNavigationView {
  &-content {
    width: 100%;
    background-color: $white;
    margin: 0 !important;
  }

  &-header {
    & .ant-typography-primary {
      color: map-get($shades-of-orange, main);
    }
    & .ant-typography-disabled {
      width: 100%;
      display: block;
      margin-bottom: 16px;
    }
  }

  &-files {
    background-color: map-get($shades-of-grey, lighter);
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0 !important;

    & .ant-table-thead > tr > th {
      background-color: transparent !important;
    }

    .ant-table-tbody > tr.ant-table-row-selected td {
      background-color: map-get($shades-of-orange, medium) !important;
      color: $white;

      & a {
        color: $white;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.EditAttributesNavigationView-content {
  .documentTitleLabel {
  	flex-grow: 1;
  }
  .tagTitle {
    line-height: 15px !important;
    font-size: 12px !important;
    color: $black;
  }
  .editFilesFileNameLabel {
    color: $black !important;
    font-size: 16px;
  }
  .editFilesRadioButton {
    margin-bottom: 16px !important;
    .ant-radio-button-wrapper {
      font-weight: 500;
      border-radius: 0 !important;
    }
  }

  .resultsTableColAttributes {}
  .resultsTableColTag {}
}
