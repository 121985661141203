@import './CommonColors.scss';

.commonSearchBar {
	background-color: map-get($shades-of-grey, lighter);
	padding: 16px;
	border-radius: 8px;


	.searchBarInput {
		input {
			border: 1px solid map-get($shades-of-grey, dark);
			border-radius: 2px !important;
		}
		input:disabled {
			background-color: white !important;
			opacity: .5!important;
			border: 1px solid map-get($shades-of-grey, dark) !important;
			border-radius: 2px !important;
		}
	}
	.anticon-search {
		color: map-get($shades-of-grey, dark) ;
	}
	/* Buttons */
	.searchButton {
		font-weight: 500;
	}
	.filterButton {
		width: 40px;
	}
	.filterButton:focus {
		background: white !important;	
	}
	.filterButton.selected {
		background: #e5e5e5 !important;
		outline: none !important;
		-webkit-box-shadow: inset 0px 0px 4px #888787 !important;
		-moz-box-shadow: inset 0px 0px 4px #888787 !important;
		box-shadow: inset 0px 0px 3px #5a5959 !important;
	}
	/* Select */
	.searchTypeSelect {
		min-width: 110px;
		.ant-select-selection {
			border-radius: 0 !important;
		}	
		.ant-select-selection__rendered::after {
			width: 4px !important;
		}
	}
	
	/* Grid */
	.searchInputColumn {
		flex-grow: 1;
		.ant-form-explain {
			position: absolute;
			font-size: 12px;
			z-index: 99;
		}
		.ant-form-item {
			margin-bottom: 0 !important;
		}
	}
	.buttonColumn {
		margin-left: 10px;
	}
	/* Suggestion */
	.suggestionMainLabel {
		margin-top: 6px;
		color: grey !important;
	}
	.suggestionButton {
		margin-left: -14px;
		margin-right: -14px;
		color: grey !important;
		&:hover {
			color: map-get($shades-of-orange, main) !important;
		}
	}
	.suggestionPreLabel {
		font-weight: 300;
		margin-right: 4px;
		color: inherit;
	} 
	.suggestionHighlightedLabel {
		font-weight: 500;
		color: inherit;
	} 
	.suggestionPostLabel {
		font-weight: 300;
		margin-left: 4px;
		color: inherit;
	}
}
