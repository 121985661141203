.confirmationModal {
  .ant-modal-header {
    border-bottom: 0 !important;
  }
  .ant-modal-footer {
    border-top: 0 !important;
  }
  // Buttons
  .cancelButton {

  }
  .confirmationButton {
    margin-left: 16px;
  }
}
.footerSection {
  margin-top: 20px;
}
